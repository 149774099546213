import React,{useState,useEffect} from "react"
import { Container, Row, Col, Modal } from "react-bootstrap"
import "./CampaignIconModule.scss"
import FormFields from "../../../static/forms/brochure.json"
import DefaultForm from "../forms/default-form-layout"
import brochure from "../../images/brochure-img.png"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import ScrollAnimation from "react-animate-on-scroll"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const CampaignIconModule = props => {

  const [show, setShow] = useState(false)

  const {isLargeScreen}=useDeviceMedia()


  useEffect(()=>{
    const getId=document.getElementById("formOpen");;
    getId.addEventListener("click",()=>{
    setShow(true)
  })
  },[props])
  
  const handleClose = () => {
    setShow(false)
  }

  const formTitle="Download the Brochure"
  const brochureUrl=props.campaignData?.brochure_link?.url


  return (
    <section className="campaign-icon-module section_item">
      <Container>
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
        <div className="campaign-top-block">
          {props.title && <h2>{props.title}</h2>}

          {props.description && (
            <ContentModule Content={props.description?.data?.description} />
          )}
        </div>
        </ScrollAnimation>
        {props?.icon_module?.length > 0 && (
          <div className="icon-text-holder">
            {props?.icon_module?.map((item, i) => {
              var imagename = "campign.image.icon_image_text"

              let processedImages = JSON.stringify({})
              if (props?.imagetransforms) {
                processedImages = props?.imagetransforms?.image_Transforms
              }
              return (
                <>
                <ScrollAnimation className="icon-item-bk" animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
                {/* <div className="icon-item-bk"> */}

                  {/* <ImageModule
                    ImageSrc={item?.image}
                    altText={`${
                      item?.image?.alternativeText
                        ? item?.image?.alternativeText
                        : item.title
                        ? item.title
                        : ""
                    } icon`}
                    imagetransforms={processedImages}
                    renderer="pic-src"
                    imagename={imagename}
                    strapi_id={props?.id}
                    classNames="img-fluid"
                  /> */}

                  <img src={item?.image?.url} alt={item?.image?.alternativeText
                        ? item?.image?.alternativeText
                        : item.title} />

                  <div className="title">{item?.title}</div>
                  {item.description && (
                    <ContentModule
                      Content={item.description?.data?.description}
                    />
                  )}
                {/* </div> */}
                </ScrollAnimation>
                {(i%2===0&&isLargeScreen)?
                <ScrollAnimation className="vetical-line" animateIn="animate__slideInUp" animateOnce delay={100} offset={100}></ScrollAnimation>
                // <div className="vetical-line"></div>
                :
                (i+1)%4!==0&&!isLargeScreen&&
                <ScrollAnimation className="vetical-line" animateIn="animate__slideInUp" animateOnce delay={100} offset={100}></ScrollAnimation>
                // <div className="vetical-line"></div>
                }
                </>
              )
            })}
          </div>
        )}
      </Container>

      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form brochure"
      >
        <Modal.Body>
          <div className="popup-form-wrapper brochure">
          <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon brochure-cancel"></i>
          </div>
          <div className="image-section">
                <img src={brochure} alt="brochure"/>
          </div>

            <div>
            
              <DefaultForm 
              fields={FormFields} 
              formTitle={formTitle} 
              emailSubUser={props.campaignData?.title+" "+"Download Brochure"}
              emailSubAdmin={props.campaignData?.title+" "+"Download Brochure"}
              pageTitle={props.campaignData?.title}
              brochureUrl={brochureUrl}
              form_id_value="campign-brochure-id-1"
              phone_id_value="desktop-id-campaign-brochure-1"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default CampaignIconModule
