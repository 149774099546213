import parse from "html-react-parser"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/campaign.json"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import DefaultForm from "../forms/default-form-layout"
import "./CampaignBanner.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const CampaignBanner = ({ campaignData }) => {
  const [show, setShow] = useState(false)
  const bannerData = campaignData
  const {
    banner_image,
    banner_content,
    developer,
    logo,
    title,
    strapi_id,
    form_data,
    units_text,
    address,
  } = bannerData

  let processedImages = JSON.stringify({})
  if (bannerData?.imagetransforms) {
    processedImages = bannerData?.imagetransforms?.banner_image_Transforms
  }

  const formContent = form_data?.length > 0 ? form_data[0] : ""

  const userText = parse(formContent?.user_content?.data?.user_content)

  const { isLargeScreen } = useDeviceMedia()

  var imagename = "campign.banner_image.campaign_banner_image"
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const formTitle = bannerData?.contact_info?.title

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <section className="campaign-banner">
      <ImageModule
        ImageSrc={banner_image}
        altText={title}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={strapi_id}
        classNames="img-fluid banner-img"
      />
      <div className="overlay-bg"></div>
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="capaign-banner-sections">
            <div className="campaign-banner-content">
              <div className="content-section">
                {logo?.url && (
                  <div className="logo-campaign">
                    <img
                      src={logo?.url}
                      alt={
                        logo?.altText ? logo.alternativeText : "logo campaign"
                      }
                    />
                  </div>
                )}
                <h1 className="title">{title}</h1>
                {developer && (
                  <p className="developer">
                    <span>by</span> {developer}
                  </p>
                )}
                <p className="banner-description">
                  <ContentModule
                    Content={banner_content?.data?.banner_content}
                  />
                </p>
                <button
                  onClick={handleModal}
                  className="button button-filled-green"
                >
                  register your interest
                </button>
                {isLargeScreen && <p className="offer-text">{units_text}</p>}
              </div>
            </div>
            {!isLargeScreen && (
              <div className="campaign-banner-form">
                <div className="campaign-form-wrapper">
                  <DefaultForm
                    fields={FormFields}
                    formtagclassname="campaign-form-desktop"
                    formTitle={formTitle}
                    userContent={userText}
                    to_email_id={formContent?.admin_mail}
                    emailSubUser={formContent?.email_subject_user}
                    emailSubAdmin={formContent?.email_subject_admin}
                    form_type="campaign-banner"
                    selectType="campaign"
                    units_text={units_text}
                    thankingAddress={address}
                    sourceUrl={pageurl}
                    // showOnlyme={true}
                    pageTitle={campaignData?.title}
                    form_id_value="campign-banner-id"
                    phone_id_value="desktop-id-campaign"
                  />
                </div>
              </div>
            )}
          </div>
        </Container>
      </ScrollAnimation>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form-campaign"
      >
        <Modal.Body>
          <div className="popup-form-wrapper-campaign">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                fields={FormFields}
                formTitle={formTitle}
                userContent={userText}
                to_email_id={formContent?.admin_mail}
                emailSubUser={formContent?.email_subject_user}
                emailSubAdmin={formContent?.email_subject_admin}
                selectType="campaign"
                thankingAddress={address}
                // showOnlyme={true}
                form_id_value="campign-banner-id-mobile"
                phone_id_value="mobile-id-campaign"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default CampaignBanner
