import { graphql } from "gatsby"
import React from "react"
import CampaignBanner from "../components/CampaignBanner/CampaignBanner"
import CampaignColBlock from "../components/CampaignColBlock/CampaignColBlock"
import CampaignIconModule from "../components/CampaignIconModule/CampaignIconModule"
import CampaignPayment from "../components/CampaignPayment/CampaignPayment"
import CampaignProperties from "../components/CampaignProperties/CampaignProperties"
import CampignWithIcon from "../components/CampignWithIcon/CampignWithIcon"
import ContactDetails from "../components/ContactDetails/ContactDetails"
import GallerySlider from "../components/GallerySlider/GallerySlider"
import SecondaryHeader from "../components/SecondaryHeader/SecondaryHeader"
import SectionViewerComponent from "../components/SectionViewerComponent/SectionViewerComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"

const CampaignTemplate = ({ data }) => {
  const campaignData = data?.strapiCampign

  const campaignProperty = data?.allStrapiNewDevelopments?.edges

  const whatsappData =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]

  let crmIds = campaignData?.property_id?.split(",")

  const locationData = [campaignData?.location_module]

  const nodeList =
    typeof document !== "undefined" ? document.querySelectorAll("section") : ""
  const array = [...nodeList]
  const filteredArray = array.filter(element => element.hasAttribute("id"))
  const idArray = filteredArray.map(element => element.getAttribute("id"))

  const { isTabletOnly, isLargeScreen } = useDeviceMedia()

  // useEffect(() => {
  //   if (!_.isEmpty(campaignProperty)) return

  //   const query = qs.stringify(
  //     {
  //       filters: {
  //         crm_id: {
  //           $in: crmIds,
  //         },
  //         publish: {
  //           $eq: true,
  //         },
  //       },
  //     },
  //     {
  //       encodeValuesOnly: true, // prettify URL
  //     }
  //   )

  //   const url = `${process.env.GATSBY_STRAPI_SRC}/api/properties?${query}`

  //   setLoading(true)
  //   fetch(url, {
  //     headers: new Headers({
  //       Authorization: `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`,
  //     }),
  //   })
  //     .then(response => response.json())
  //     .then(({ data }) => {
  //       setCampaingProperty(data)
  //       setLoading(false)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }, [crmIds])

  return (
    <Layout isCampaign>
      
      <SecondaryHeader
        idArray={idArray}
        isCampaign
        whatsapp_num={campaignData?.whatsapp_no}
      />
     
      <CampaignBanner campaignData={campaignData} />
      {isLargeScreen && (
        <SectionViewerComponent
          idArray={idArray}
          isCampaign
          whatsapp_num={campaignData?.whatsapp_no}
        />
      )}
      {campaignData?.amenities_data?.icon_module && (
        <CampignWithIcon
          id={campaignData.strapi_id}
          icon_module={campaignData?.amenities_data?.icon_module}
          {...campaignData?.amenities_data}
          imagetransforms={campaignData?.imagetransforms}
          campaign={true}
        />
      )}
      {campaignData?.image_text_block?.left_right_block?.length > 0 && (
        <CampaignColBlock
          campaignData={campaignData}
          id={campaignData.strapi_id}
          imagetransforms={campaignData?.imagetransforms}
          {...campaignData?.image_text_block}
          campaign={true}
          singleblock={true}
        />
      )}
      {locationData?.length > 0 && locationData[0] && (
        <CampaignColBlock
          id={campaignData.strapi_id}
          imagetransforms={campaignData?.imagetransforms}
          left_right_block={locationData}
          campaign={true}
          singleblock={true}
          locationblock={true}
          campaignData={campaignData}
        />
      )}
      {campaignData?.gallery_image_list?.length > 0 && (
        <GallerySlider galleryData={campaignData} />
      )}
      {campaignData?.development_data?.icon_module?.length > 0 && (
        <CampaignIconModule
          {...campaignData?.development_data}
          id={campaignData.strapi_id}
          imagetransforms={campaignData?.imagetransforms}
          icon_module={campaignData?.development_data?.icon_module}
          campaignData={campaignData}
        />
      )}
      {campaignData?.payment && (
        <CampaignPayment
          paymentData={campaignData?.payment}
          id={campaignData.strapi_id}
          imagetransforms={campaignData?.imagetransforms}
        />
      )}
      {campaignData?.single_image_block?.length > 0 && (
        <CampaignColBlock
          id={campaignData.strapi_id}
          left_right_block={campaignData?.single_image_block}
          campaign={true}
          singleblock={true}
          campaignData={campaignData}
          imagetransforms={campaignData?.imagetransforms}
        />
      )}
      {campaignProperty?.length > 0 && (
        <CampaignProperties propertyData={campaignProperty}  campaignData={campaignData}/>
      )}
      <ContactDetails
        whatsappData={whatsappData}
        data={campaignData}
        type="campaign"
      />
    </Layout>
  )
}


export const Head = ({ data }) => {
  const campData = data?.strapiCampign
  let myimgtransforms = ""
  if (campData?.imagetransforms?.banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      campData?.imagetransforms?.banner_image_Transforms
    )
  }

  let imageUrl=""

  const findImage = campData?.banner_image?.url?.substring(campData?.banner_image?.url?.indexOf("i.prod"));
  const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
  imageUrl=ProcessedResult?ProcessedResult["768x397"]:campData?.banner_image?.url

  const metaTitle = `${campData?.title}`
  const metaDesc = `Know more about ${campData?.title} here.`

  return <SEO title={metaTitle} description={metaDesc} imageUrl={imageUrl}></SEO>
}

export default CampaignTemplate

export const query = graphql`
  query ($page_id: String, $property_id: [String!]!) {
    strapiCampign(id: { eq: $page_id }) {
      ...CampaignFragment
    }
    allStrapiNewDevelopments(filter: { crm_id: { in: $property_id } }) {
      edges {
        node {
          images {
            strapi_json_value {
              url
            }
          }

          slug
          id
          imagetransforms {
            left_right_block_Transforms
          }
          project_title
          crm_id
          development_type
          developer
          strapi_id
          area
          search_type
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          whatsapp_message
        }
      }
    }
  }
`
